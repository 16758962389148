import React from 'react';
import s from './Footer.module.css';
import classNames from 'classnames';
import { CiFacebook, CiTwitter, CiLinkedin } from 'react-icons/ci';
import { LuMail } from 'react-icons/lu';

const Footer = () => {
  return (
    <footer className={s.footer}>
      <div className={classNames("container", s.footer_content)}>
        <div className={s.footer_section}>
          <h3>About Us</h3>
          <p>
            SPYRE GROUP S.R.O. specializes in software provision, IT consulting, 
            data processing, hosting, and web portals. We are committed to delivering 
            innovative solutions to drive digital transformation.
          </p>
          <p>Company Number: 220 89 390</p>
        </div>
        <div className={s.footer_section}>
          <h3>Contact</h3>
          <p>Address: Hybešova 985/30, Staré Brno, 602 00 Brno, Czechia</p>
          <p className={s.email}>
            <LuMail className={s.email_icon} />
            <a href="mailto:spyre482@gmail.com" className={s.email_link}>spyre482@gmail.com</a>
          </p>
        </div>
        <div className={s.footer_section}>
          <h3>Follow Us</h3>
          <div className={s.social_icons}>
            <a href='https://facebook.com' target='_blank' rel='noopener noreferrer' aria-label="Facebook">
              <CiFacebook />
            </a>
            <a href='https://twitter.com' target='_blank' rel='noopener noreferrer' aria-label="Twitter">
              <CiTwitter />
            </a>
            <a href='https://linkedin.com' target='_blank' rel='noopener noreferrer' aria-label="LinkedIn">
              <CiLinkedin />
            </a>
          </div>
        </div>
      </div>
      <div className={s.footer_bottom}>
        <p>&copy; {new Date().getFullYear()} SPYRE GROUP S.R.O. All rights reserved.</p>
      </div>
    </footer>
  );
};

export default Footer;
