import React from 'react';
import s from './Testimonials.module.css';
import avt01 from '../../../../assets/avt01.png';
import avt02 from '../../../../assets/avt02.png';
import { FaStar } from 'react-icons/fa';
import classNames from 'classnames';

const testimonialsData = [
  {
    name: "Lisa M.",
    title: "Operations Manager, Tech Solutions Corp",
    img: avt01,
    comment: "Working with SPYRE GROUP S.R.O. has been a game-changer. Their software solutions transformed our operations, providing us with tools that are intuitive and efficient. Their consulting team is insightful and always ahead of industry trends."
  },
  {
    name: "Tom R.",
    title: "Head of Digital Engagement, Global Reach Ltd.",
    img: avt02,
    comment: "Our website portal project was handled with professionalism from start to finish. SPYRE GROUP S.R.O. provided exactly what we needed for both usability and security, which has enhanced our customer interactions significantly."
  },
  {
    name: "Sara W.",
    title: "CEO, Insightful Data Co.",
    img: avt01,
    comment: "Their data processing and analytics services helped us tap into insights we didn’t even know were there. Now, we’re able to make informed decisions that impact our bottom line."
  },
  {
    name: "Mark T.",
    title: "IT Manager, BusinessNet Inc.",
    img: avt02,
    comment: "Thanks to SPYRE GROUP S.R.O.'s hosting services, we’ve had zero downtime, and their dedicated support team always provides quick responses when we have questions."
  }
];

const Testimonials = () => {
  return (
    <div className={classNames(s.testimonials, "container")} id="testimonials">
      <h2 className={s.main_title}>Testimonials</h2>
      <div className={s.container}>
        {testimonialsData.map(item => (
          <div className={s.box} key={item.name}>
            <div className={s.image}>
              <img src={item.img} alt="avatar" />
            </div>
            <h3>{item.name}</h3>
            <span className={s.title}>{item.title}</span>
            <div className={s.rate}>
              <FaStar className={s.filled} />
              <FaStar className={s.filled} />
              <FaStar className={s.filled} />
              <FaStar className={s.filled} />
              <FaStar className={s.filled} />
            </div>
            <p>{item.comment}</p>
          </div>
        ))}
      </div>
    </div>
  );
};

export default Testimonials;
