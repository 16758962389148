import React from 'react';
import s from './Hero.module.css';
import hero1 from '../../../../assets/hero.jpg';
import { FaQuoteLeft } from 'react-icons/fa';
import classNames from 'classnames';

const Hero = () => {
  return (
    <section className={classNames(s.hero, "container")} id='home'>
      <div className={s.hero_content}>
        <h1>SPYRE GROUP S.R.O.</h1>
        <p>
          At SPYRE GROUP S.R.O., we are committed to driving innovation in the digital world through expert software solutions, IT consulting, data processing, hosting, and web portal development. With years of industry experience, we help businesses streamline operations, enhance digital capabilities, and unlock new growth opportunities. Our consultative approach ensures every project is tailored to your specific goals.
        </p>
        <a href='#services' className={s.get_started_button}>Explore Our Services</a>
      </div>
      <div className={s.hero_image}>
        <img src={hero1} alt='SPYRE GROUP S.R.O.' />
        <div className={s.hero_image_text}>
          <FaQuoteLeft className={s.icon} />
          <p>
            Empowering businesses with reliable technology solutions that make a measurable impact.
          </p>
        </div>
      </div>
    </section>
  );
};

export default Hero;
