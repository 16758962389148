import React from 'react';
import s from './WhyChooseUs.module.css';
import classNames from 'classnames';
import { FaIndustry, FaRegLightbulb, FaHandsHelping, FaChartBar, FaCheckCircle } from 'react-icons/fa'; // Updated icons

const WhyChooseUs = () => {
    return (
      <section className={classNames(s.why_choose_us, "container")} id='about'>
        <h2>Why Choose Us?</h2>
        <div className={s.why_choose_us_cards}>
          <div className={s.why_choose_us_card}>
            <FaIndustry className={s.why_choose_us_icon} />
            <h3>Industry Expertise</h3>
            <p>Our team of specialists brings extensive experience and technical knowledge, ensuring that each solution is both cutting-edge and highly functional.</p>
          </div>
          <div className={s.why_choose_us_card}>
            <FaRegLightbulb className={s.why_choose_us_icon} />
            <h3>Customized Solutions</h3>
            <p>We understand that each business is unique, and we work to tailor every solution to meet your specific needs, providing optimal results and maximum impact.</p>
          </div>
          <div className={s.why_choose_us_card}>
            <FaHandsHelping className={s.why_choose_us_icon} />
            <h3>Dedicated Support</h3>
            <p>Our team provides ongoing support and maintenance, ensuring your systems operate smoothly and adapting to your evolving needs.</p>
          </div>
          <div className={s.why_choose_us_card}>
            <FaChartBar className={s.why_choose_us_icon} />
            <h3>Proactive Approach</h3>
            <p>We prioritize strategic foresight and proactive planning, so you’re not only prepared for today’s needs but positioned for future growth.</p>
          </div>
          <div className={s.why_choose_us_card}>
            <FaCheckCircle className={s.why_choose_us_icon} />
            <h3>Proven Results</h3>
            <p>We have a track record of successful projects and satisfied clients who have seen real improvements in efficiency, user experience, and security.</p>
          </div>
        </div>
      </section>
    );
};

export default WhyChooseUs;
