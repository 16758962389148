import React from 'react';
import s from './Services.module.css';
import { FaCode, FaLaptop, FaDatabase, FaCloud, FaLaptopCode } from 'react-icons/fa';
import classNames from 'classnames';

const Services = () => {
  return (
    <section className={classNames(s.services, "container")} id='services'>
      <h2>Our Services</h2>
      <p>
        Our range of specialized services covers every aspect of modern digital infrastructure and business solutions. Here’s what we offer:
      </p>
      <div className={s.service_cards}>
        <div className={s.service_card}>
          <FaCode className={s.icon} />
          <h3>Software Provision & Development</h3>
          <ul>
            <li>Custom software solutions to meet your business needs, enhancing efficiency and productivity.</li>
            <li>Off-the-shelf software provisioning for a seamless fit into your organization.</li>
          </ul>
        </div>
        <div className={s.service_card}>
          <FaLaptop className={s.icon} />
          <h3>IT Consulting</h3>
          <ul>
            <li>Expert advice on IT infrastructure and cybersecurity, aligning technology with business strategy.</li>
            <li>Helping businesses navigate technological challenges and drive digital transformation.</li>
          </ul>
        </div>
        <div className={s.service_card}>
          <FaDatabase className={s.icon} />
          <h3>Data Processing & Analytics</h3>
          <ul>
            <li>Transforming complex data into actionable insights for better decision-making.</li>
            <li>Optimizing processes and gaining a competitive edge with data-driven strategies.</li>
          </ul>
        </div>
        <div className={s.service_card}>
          <FaCloud className={s.icon} />
          <h3>Hosting Solutions</h3>
          <ul>
            <li>Reliable and secure hosting services, including cloud hosting and dedicated servers.</li>
            <li>Hybrid solutions ensuring data security and uptime tailored to your business scale.</li>
          </ul>
        </div>
        <div className={s.service_card}>
          <FaLaptopCode className={s.icon} />
          <h3>Web Portals & Application Development</h3>
          <ul>
            <li>Developing robust web portals and internal applications for enhanced user experience.</li>
            <li>Seamless interaction and intuitive user interface design for your digital solutions.</li>
          </ul>
        </div>
      </div>
    </section>
  );
};

export default Services;
