import React from 'react';
import { LuMail } from 'react-icons/lu';
import { IoLocationOutline } from "react-icons/io5";
import s from './ContactUs.module.css'; 
import classNames from 'classnames';

const ContactUs = () => {
  return (
    <section className={classNames(s.contact_us, "container")} id='contact'>
      <h2>Contact Us</h2>
      <p>You can contact us at the email below, and we'll get back to you as soon as possible.</p>
      <div className={s.block}>
        <LuMail className={s.icon} />
        <a className={s.mail} href='mailto:spyre482@gmail.com'>
          spyre482@gmail.com
        </a>
      </div>
      <div className={s.block}>
        <IoLocationOutline className={s.icon} />
        <p>Hybešova 985/30, Staré Brno, 602 00 Brno, Czechia</p>
      </div>
    </section>
  );
};

export default ContactUs;
